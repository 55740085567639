import { APP_ROUTE } from '@core/constants';
import { ClientUser } from '@core/models';
import { environment } from '@environments/environment';

export const HEADER_ACCOUNT_NAV_LIST = (user: ClientUser, isSideNav: boolean, isAgentHub = false, isBusinessPage = false, viewport) =>
	[
		user?.id &&
			!isAgentHub &&
			!isBusinessPage && {
				path: '/' + APP_ROUTE.myProfile,
				data: {
					icon: 'account_circle',
					title: 'n12',
				},
			},

		!user?.id &&
			!isAgentHub && {
				path: '/' + APP_ROUTE.placeAd,
				data: {
					icon: 'add_circle',
					title: 'n20',
				},
			},

		+user?.hasBusinessProfile && {
			path: `/${APP_ROUTE.agentHub.dashboard}/${APP_ROUTE.businessProfile.base}`,
			data: {
				iconImage: 'bussiness-profile',
				title: 'n2966',
			},
		},
		user?.id &&
			!isAgentHub && {
				path: '/' + APP_ROUTE.favorites,
				data: {
					icon: 'favorite_border',
					title: 'n3',
				},
			},
		user?.id &&
			!isAgentHub &&
			!isBusinessPage && {
				path: '/' + APP_ROUTE.myAdList,
				data: {
					icon: 'view_carousel',
					title: 'n5',
				},
			},
		user?.id &&
			isBusinessPage &&
			!viewport.desktop && {
				path: `/${APP_ROUTE.agentHub.dashboard}/${APP_ROUTE.agentHub.listings}`,
				data: {
					icon: 'summarize',
					title: 'n4094',
				},
			},
		user?.id &&
			isBusinessPage &&
			!viewport.desktop && {
				path: `/${APP_ROUTE.agentHub.dashboard}/${APP_ROUTE.agentHub.enquiries}`,
				data: {
					icon: 'mail',
					title: 'n3894',
				},
			},
		user?.id &&
			isBusinessPage &&
			!viewport.desktop && {
				path: `/${APP_ROUTE.agentHub.dashboard}/${APP_ROUTE.agentHub.agents}`,
				data: {
					icon: 'person',
					title: 'n3505',
				},
			},

		!isBusinessPage && {
			path: '/' + APP_ROUTE.propertyWorth,
			data: {
				icon: 'notifications',
				title: 'n4093',
				isNew: true,
			},
		},
		user?.id && {
			path: '/' + APP_ROUTE.propertyAlerts,
			data: {
				icon: 'notifications',
				title: 'n4092',
				isNew: true,
			},
		},
		user?.id && {
			path: '/' + APP_ROUTE.meetingSchedule.base,
			data: {
				icon: 'link',
				title: 'n3756',
				isNew: true,
			},
		},
		!isBusinessPage &&
			!viewport.desktop && {
				data: {
					icon: 'home_work',
					title: 'n251',
				},
			},
		!isBusinessPage &&
			!viewport.desktop && {
				path: '/' + APP_ROUTE.rentAdsList,
				data: {
					icon: ' ',
					title: 'n793',
					isSubmenu: true,
				},
			},
		!isBusinessPage &&
			!viewport.desktop && {
				path: '/' + APP_ROUTE.commercialtolet,
				data: {
					icon: ' ',
					title: 'n3316',
					isSubmenu: true,
				},
			},
		!isBusinessPage &&
			!viewport.desktop && {
				data: {
					icon: 'real_estate_agent',
					title: 'n250',
				},
			},
		!isBusinessPage &&
			!viewport.desktop && {
				path: '/' + APP_ROUTE.sellresidential,
				data: {
					icon: ' ',
					title: 'n3592',
					isSubmenu: true,
				},
			},
		!isBusinessPage &&
			!viewport.desktop && {
				path: '/' + APP_ROUTE.sellcommercial,
				data: {
					icon: ' ',
					title: 'n3593',
					isSubmenu: true,
				},
			},
		!isBusinessPage &&
			!viewport.desktop && {
				data: {
					icon: 'add_home_work',
					title: 'n794',
				},
			},
		!isBusinessPage &&
			!viewport.desktop && {
				path: '/' + APP_ROUTE.apartments,
				data: {
					icon: ' ',
					title: 'n3594',
					isSubmenu: true,
				},
			},
		!isBusinessPage &&
			!viewport.desktop && {
				path: '/' + APP_ROUTE.houses,
				data: {
					icon: ' ',
					title: 'n785',
					isSubmenu: true,
				},
			},

		+user?.enquiryCount &&
			!isAgentHub && {
				path: '/' + APP_ROUTE.myEnquiries,
				data: {
					icon: 'mark_email_read',
					title: 'n3894',
				},
			},

		+user?.hasBusinessProfile &&
			(isSideNav || isAgentHub) && {
				path: isAgentHub ? APP_ROUTE.rentAdsList : isBusinessPage ? APP_ROUTE.home : '/' + APP_ROUTE.agentHub.dashboard,
				data: {
					icon: 'autorenew',
					title: isAgentHub ? 'n4076' : isBusinessPage ? 'n4076' : 'n4075',
				},
			},
	].filter(Boolean);

export const HEADER_OTHER_NAV_LIST = [
	// TODO: Confirm in the design why these nav items are disabled.
	{
		path: '/' + APP_ROUTE.about,
		data: {
			icon: 'info',
			title: 'n58',
			isOther: true,
		},
	},

	{
		path: '/' + APP_ROUTE.terms,
		data: {
			icon: 'checklist',
			title: 'n61',
			isOther: true,
		},
	},
	{
		path: '/' + APP_ROUTE.privacyPolicy,
		data: {
			icon: 'shield',
			title: 'n62',
			isOther: true,
		},
	},
	{
		data: {
			icon: 'help',
			title: 'n3073',
			isOther: true,
			isPopup: true,
		},
	},
	{
		path: '/' + APP_ROUTE.cookiePolicy,
		data: {
			icon: 'cookie',
			title: 'n3124',
			isOther: true,
		},
	},

	{
		path: environment.feedbackUrl,
		data: {
			icon: 'feedback',
			title: 'n3278',
			isOther: true,
			newTab: true,
		},
	},

	{
		path: '/' + APP_ROUTE.contact,
		data: {
			icon: 'contact_support',
			title: 'n60',
			isOther: true,
		},
	},
];
