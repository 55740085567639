export const DAYS_INDEX = {
	1: 1,
	2: 2,
	3: 3,
	4: 4,
	5: 5,
	6: 6,
	7: 0,
};

export const TIME_INTERVALS = [
	{
		value: '5',
		name: '5 minutes',
	},
	{
		value: '10',
		name: '10 minutes',
	},
	{
		value: '15',
		name: '15 minutes',
	},
	{
		value: '20',
		name: '20 minutes',
	},
	{
		value: '30',
		name: '30 minutes',
	},
];
