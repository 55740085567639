import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { HEADER_ACCOUNT_NAV_LIST } from '@core/components/header/header.routes';
import { AuthService } from '@core/data-access/services/auth.service';
import { ClientUser } from '@core/models';

@Pipe({
	name: 'navMenuItems',
})
export class NavMenuItemsPipe implements PipeTransform {
	constructor(
		private readonly auth: AuthService,
		private router: Router
	) {}
	localUser = this.auth.user();

	transform(user: ClientUser, isSideNav: boolean, isAgentHub: boolean = false, isBusinessPage: boolean = false, viewport) {
		const UPDATED_DETAILS = { ...this.localUser, ...user };
		if (this.router.url.includes('/dashboard')) {
			isBusinessPage = true;
		} else {
			isBusinessPage = false;
		}
		return HEADER_ACCOUNT_NAV_LIST(UPDATED_DETAILS, isSideNav, isAgentHub, isBusinessPage, viewport);
	}
}
